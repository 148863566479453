<template>
  <div class="hello">
    <div class="copyContainer">
      <h1>DESIGN + ENTWICKLUNG</h1>
      <h1>Analog. Digital.</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .hello{
    position: absolute;
    border: 1px solid red;
    top: 50%;
  }
  .copyContainer{
    margin-top: - calc(100% / 2);
  }
h1 {
  // margin: 40px 0 0;
  text-align: left;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
