<template>
  <div id="app" class="wrapper ">
    <div class="hello" id="copyContainer">
    <div  class="headlines d-flex flex-column" >
      <div class="p-2 headline" v-bind:style="{ fontSize: hlFontSize }" :class="{noLineBreak: showHeadlineLineBreaks}">DESIGN + ENTWICKLUNG</div>
      <div class="p-2 w-100">
        <div class="d-flex flex-wrap">
          <div class="p-0 headline flex-grow-1" v-bind:style="{ fontSize: hlFontSize }" style="min-width:250px;">Analog. Digital.</div>
          <div class="p-0 kontaktCopy flex-shrink-1">
            sonnig GmbH<br>
            Adalbertstraße 9<br>
            10999 Berlin <br>
            kontakt@sonnig.io<br>
          </div>
        </div>
      </div>
      <!-- <h1 id="headline">DESIGN + ENTWICKLUNG<br>
      Analog. Digital.</h1> -->
    </div>
  </div>
  </div>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import { gsap, Expo, Power4 } from "gsap";
// import { gsap, Back, Sine, Quad, Expo, Power4 } from "gsap";
export default {
  name: 'App',
  components: {
    HelloWorld
  },
  data(){
    return {
      resizeTimeout:null,
      headlineSize:100,
      // showLineBreaks: false
    }
  },
  computed:{
    hlFontSize(){
      return this.headlineSize.toString() + 'px'
    },
    showHeadlineLineBreaks(){
      if(window.innerWidth > 900){
        return true
      }
      return false;
    }
  },
  methods:{
    onResize(e) {
      // console.log(e)
      if(this.resizeTimeout){
        clearTimeout(this.resizeTimeout)
      }
      this.resizeTimeout = setTimeout(() => {
        
        const that = this
        // gsap.to('#copyContainer', {
        //   duration: 0.1, ease: Power4.easeInOut, opacity: 0,
        //   onComplete: function(){
        //     // console.log('complete');
        //   }
        // });
        that.changeCopyStuff()  
        
        //this.changeCopyStuff()
        //this.bannerDown()
      }, 400);
    },
    changeCopyStuff(){
      if(this.resizeTimeout){
        clearTimeout(this.resizeTimeout)
      }
      let _maxWidth = 1700.0;
      let _minWidth = 400.0;
      
      let _maxFontSize = 100.0;
      let _minFontSize = 35.0;
      let _fontSizeRange = _maxFontSize - _minFontSize; // --- 100% FontSize
      
      let _currentWinWidth = window.innerWidth;
      if(window.innerWidth < 400){
        _currentWinWidth = 400;
      }  else if(window.innerWidth > 1700) {
        _currentWinWidth = 1700;
      }

      let _percentTemp =  (_maxWidth ) / (_currentWinWidth - _minWidth);
      let _newFontSize = (_fontSizeRange / _percentTemp) + 35;


      //console.log('_newFontSize ', _newFontSize);
      this.headlineSize = _newFontSize
      setTimeout(()=>{
        // console.log('........');
        
         const _el = document.getElementById('copyContainer');
        //  const _headlines = document.getElementsByClassName('headline');
        //  console.log(_headlines)
        //  for(var i = 0; i < _headlines.length; i ++){
        //   _headlines[i].
        //  }
        //  _headlines.forEach(element => {
        //   console.log(element)
        //   // element.style.fontSize = _newFontSize + 'px'
        //  });
        // //  marginLeft: - (_el.clientWidth / 2),
        // _headlines.style.fontSize = _newFontSize + 'px'
         
         let _left =  (window.innerWidth / 2) - (_el.clientWidth / 2)
         let _top =  (window.innerHeight / 2) - (_el.clientHeight / 2)
        // console.log(window.innerWidth)
        //   console.log('', document.getElementById('copyContainer'));
          
        // console.log(_left)
        gsap.to(_el, {
          opacity:1,
           y: _top,
           x: _left,
          duration: 0.6, ease: Expo.easeOut,
          // opacity: 1,
          // delay:0.5, 
        });
      },20)
    },
    //  bannerDown(){
    //   let tl = gsap.timeline({
    //     onStart: function(){ console.log('play') },
    //     onComplete: function(){ console.log('finish') },
    //   });
    //   tl.to('#copyContainer', 1, {
    //       x: 100,
    //       opacity: 1,
    //       ease: Power4.easeOut,
    //     }, 0)
    //     .to('#copyContainer', 1, {
    //       y: 100,
    //       ease: Power4.easeOut,
    //     }, 0);
    //     //return tl;
    // }
  },
  unmounted () {
    window.removeEventListener("resize", this.onResize);

  },
  mounted () {
    const that = this
    window.addEventListener("resize", this.onResize);
    setTimeout(()=>{
      that.changeCopyStuff()
    },1000)
  }
}

        
        // // 0.533
        //  let _fontSizeMax = ((_maxFontSize - _minFontSize) * 100) / (_maxFontSize - _minFontSize)
        //  console.log('_fontSizeMax ----', _fontSizeMax)
        // // console.log(_fontSizeMax)
        
        // let _currentWinWidth = window.innerWidth
        // if(window.innerWidth < 430){
        //   _currentWinWidth = 430;
        // }  else if(window.innerWidth > 1700) {
        //   _currentWinWidth = 1700;
        // }
        // console.log('........');
        // let _maxWinWidthFact = _maxWidth - _minWidth;
        // let _currentWinWidthFact = _currentWinWidth - _minWidth;
        
        // // let _winWidth = window.innerWidth > 1700 ? 1700.0 : window.innerWidth;
        
        // let _fontSizeScale = (( _currentWinWidth - _minWidth) / (_maxWidth - _minWidth) ) 
        
        // let _fontSizeScale2 =  _currentWinWidthFact ;
        
        // let _percentRatio = _percentTemp * _fontSizeScale2
        
        // console.log(` _fontSizeScale2 ${_fontSizeScale2}`)
        // // let _fontSizeScale2 = ((window.innerWidth - _minWidth) * 100) / (_maxWidth - _minWidth) 
        // // let _fontScale3 = _fontSizeScale2 + 45.0
        // //  let _fontSizeScale2 = (_maxFontSize / _maxWidth )  / ( _minFontSize / _minWidth )
        // // let _fontSizeScale3 = ( _maxWidth - _minWidth ) / (_maxFontSize - _minFontSize)
        // // let _fontSizeScale4 = _fontSizeScale3 / _fontSizeScale2
        
        // // console.log(`_fontScale3 ${_fontScale3}`)
        // console.log(` _percentTemp ${_percentTemp}`)
        // console.log(` _percentRatio ${_percentRatio}`)
        // // console.log(` _fontSizeScale ${_fontSizeScale}`)
        // console.log(` _currentWinWidth ${_currentWinWidth}`)
        // console.log(` _currentWinWidthFact ${_currentWinWidthFact}`)
        // console.log(` _maxWinWidthFact ${_maxWinWidthFact}`)
        
        // // console.log(`_fontSizeScale3 ${_fontSizeScale3} _fontSizeScale4 ${_fontSizeScale4}`)
        // //_fontSizeScale = _fontSizeScale > 1 ? 1 : _fontSizeScale < 0.3 ? 0.3 : _fontSizeScale;
        
        // // console.log(`window.innerWidth ${window.innerWidth} _fontSizeScale ${_fontSizeScale}`)
        
        // console.log(`_fontSize = (((_maxFontSize * _fontSizeScale)  )) ${_maxFontSize} / ${_fontSizeScale} = ${_maxFontSize / _fontSizeScale}`)
        // console.log(`_fontSize = (((_maxFontSize * _fontSizeScale2) )) ${_minWidth} / ${_fontSizeScale2} = ${( _fontSizeScale2 / _minWidth)*100}`)
        // let _fontSize = Math.floor(((_maxFontSize * _fontSizeScale) +45 ))
        // console.log(`_fontSize ${_fontSize}`)
        // // console.log(` _fontSize ${_fontSize}`)
     
        // // let _fontSize = ((window.innerWidth - _minWidth) * 100) / (_maxWidth - _minWidth)
        // // console.log(_fontSize)
        // // console.log('----')
</script>

<style lang="scss">
   @font-face {
    font-family: 'Montserrat-Black';
    src: url(./assets/font/Montserrat-Black.ttf) format("truetype");
  }
  @font-face {
    font-family: 'Montserrat-Medium';
    src: url(./assets/font/Montserrat-Medium.ttf) format("truetype");
  }
  html, body{
    background-color: #FEF400;
    font-family: 'Montserrat-Medium';
    overflow: hidden;
    width: 100vw;
  height: 100vh;

  }
  h1{
    font-family: 'Montserrat-Black';
    font-size: 100px;
  }
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat-Medium';
  color: #000;
  
}
._br{
  border: 1px solid black;
}
.wrapper{
  width: 100vw;
  height: 100vh;
  padding-left: 20px;
  padding-right: 20px;
  
}
.hello{
  position: absolute;
  // overflow: hidden;
  opacity: 0;
  
  // border: 1px solid red;
  
  top: 0px;
  left: 0px;
  // left: 50%;
}
.noLineBreak{
  white-space: nowrap;
}
.headline {
  
  
  font-family: 'Montserrat-Black';
    // font-size: 100px;
    // flex-flow: wrap;
}
.kontaktCopy {
  
  font-family: 'Montserrat-Medium';
    font-size: 28px;
    // flex-flow: wrap;
}
.headlines {
  position: relative;
  // font-family: 'Montserrat-Black';
    // font-size: 100px;
    // flex-flow: wrap;
}

.ml5{
  margin-left: 5px;
}

</style>
